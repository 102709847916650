import { InvoiceInfo } from "@api/throughShipment/throughShipmentApi";
import PopupBackground from "@component/PopupBackground";
import { Button, DatePicker, Form, Input, InputNumber, Select, Space } from "antd";
import moment from "moment";
import React from "react";
import type { RangePickerProps } from "antd/es/date-picker";
import { ModalWrapper } from "src/styles/common";
import styled from "styled-components";
import { InvoiceTypeOptions } from "@constant/InvoiceType";

const Wrapper = styled(ModalWrapper)`
  width: 400px;
`;
const Title = styled.p`
  margin-bottom: 20px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export enum InvoiceModalType {
  DATE = "date",
  TYPE = "type",
}

type Props = {
  onClose: () => void;
  onSubmit: (orderId: number, payload: InvoiceInfo) => void;
  orderId?: number;
  invoiceModalType?: InvoiceModalType;
};
export default function CreateInvoiceModal(props: Props) {
  const { onClose, onSubmit, orderId, invoiceModalType = InvoiceModalType.DATE } = props;
  const [form] = Form.useForm();

  const handleOnSubmit = () => {
    const fieldsValue = form.getFieldsValue();
    if (fieldsValue.invoiceDate) {
      fieldsValue.invoiceDate = fieldsValue.invoiceDate.format("YYYY-MM-DD");
    }
    if (orderId) {
      onSubmit(orderId, fieldsValue);
      onClose();
    }
  };
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days after today
    return current && current > moment().endOf("day");
  };

  return (
    <PopupBackground close={onClose}>
      <Wrapper>
        <Title>開立發票</Title>
        <Form form={form} onFinish={handleOnSubmit} style={{ width: "90%" }}>
          <Form.Item name="invoiceNumber" label="發票號碼" rules={[{ required: true, message: "" }]}>
            <Input />
          </Form.Item>
          {invoiceModalType === InvoiceModalType.DATE && (
            <Form.Item name="invoiceDate" label="發票日期" rules={[{ required: true, message: "" }]}>
              <DatePicker mode="date" style={{ width: "100%" }} disabledDate={disabledDate} />
            </Form.Item>
          )}
          <Form.Item name="invoiceAmount" label="發票金額" rules={[{ required: true, message: "" }]}>
            <InputNumber min={0} style={{ width: "70%" }} />
          </Form.Item>
          {invoiceModalType === InvoiceModalType.TYPE && (
            <Form.Item name="invoiceType" label="發票作業" rules={[{ required: true, message: "" }]}>
              <Select options={InvoiceTypeOptions} allowClear />
            </Form.Item>
          )}
        </Form>
        <Footer>
          <Space>
            <Button onClick={onClose}>取消</Button>
            <Button type="primary" onClick={form.submit}>
              送出
            </Button>
          </Space>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
}
