import customsClearanceApi, {
  FetchBatchUpdatePurchaseLine,
  FetchCreateCustomsClearances,
  FetchCustomsClearanceList,
  FetchCustomsClearancesPurchaseAdd,
  FetchCustomsClearancesPurchaseRemove,
  FetchUpdateCustomsClearances,
} from "@api/sharing/customsClearanceApi";
import { CustomsClearances } from "@api/utils/normalizeCustomsClearance";
import { PurchaseLines, PurchaseList } from "@api/utils/normalizeWarehouse";
import warehouseApi, { FetchPurchaseLines, PurchaseListParams } from "@api/warehouseApi";
import { VendorListType } from "@page/VendorListPage/interface";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import productApi, { VendorListResult, FetchVendorListParam } from "@api/productApi";
import { message } from "antd";
import type { RootState } from "../rootReducer";

interface IState {
  isFetching: boolean;
  isPurchaseLinesFetching: boolean;
  listRefresh: boolean;
  customsClearanceResult: {
    count: number;
    next: string;
    previous: string;
    results: CustomsClearances[];
  };
  customsClearanceFilter: {
    tariffNumber?: string;
    limit: number;
  };
  vendors: VendorListType;
  customsClearanceInfo: CustomsClearances | undefined;
  purchaseListResult: {
    count: number;
    next: string;
    previous: string;
    results: PurchaseList[];
  };
  purchaseLinesResult: {
    count: number;
    next: string;
    previous: string;
    results: { abnormalCost: boolean; data: PurchaseLines[] };
  };
  purchaseLinesFilter: {
    stoNumber: string;
    sku: string;
    limit: number;
  };
  isEditDone: boolean;
  addErrorMessage: string;
  authorityErrorMessage: string;
  isAuthorityDone: boolean;
  vendorListResult: VendorListResult;
  vendorListParam: FetchVendorListParam;
}

const initialState: IState = {
  isFetching: false,
  isPurchaseLinesFetching: false,
  listRefresh: false,
  customsClearanceResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  customsClearanceFilter: {
    tariffNumber: undefined,
    limit: 20,
  },
  vendors: [],
  customsClearanceInfo: undefined,
  purchaseListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  purchaseLinesResult: {
    count: 0,
    next: "",
    previous: "",
    results: { abnormalCost: false, data: [] },
  },
  purchaseLinesFilter: {
    stoNumber: "",
    sku: "",
    limit: 20,
  },
  isEditDone: false,
  addErrorMessage: "",
  authorityErrorMessage: "",
  isAuthorityDone: false,
  vendorListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  vendorListParam: {
    nameQ: "",
    limit: 20,
    offset: 0,
  },
};

export const fetchCustomsClearanceList = createAsyncThunk(
  "customsClearance/fetchCustomsClearanceList",
  async (filterOptions: FetchCustomsClearanceList) => {
    const response = await customsClearanceApi.fetchCustomsClearancesList(filterOptions);
    return response;
  },
);

export const fetchDeleteCustomsClearance = createAsyncThunk(
  "customsClearance/fetchDeleteCustomsClearance",
  async (config: { id: number; page: number }) => {
    await customsClearanceApi.fetchDeleteCustomsClearances(config.id);
    return "Success";
  },
);

export const fetchCreateCustomsClearances = createAsyncThunk(
  "customsClearance/fetchCreateCustomsClearances",
  async (payload: FetchCreateCustomsClearances) => {
    await customsClearanceApi.fetchCreateCustomsClearances(payload);
    return "Success";
  },
);

export const fetchCustomsClearance = createAsyncThunk(
  "customsClearance/fetchCustomsClearance",
  async (customsClearancesId: number) => {
    const response = await customsClearanceApi.fetchCustomsClearances(customsClearancesId);
    return response;
  },
);

export const fetchUpdateCustomsClearance = createAsyncThunk(
  "customsClearance/fetchUpdateCustomsClearance",
  async (payload: FetchUpdateCustomsClearances, thunkApi) => {
    await customsClearanceApi.fetchUpdateCustomsClearances(payload);
    await thunkApi.dispatch(fetchCustomsClearance(payload.customsClearancesId));
    return "Success";
  },
);

export const fetchPurchaseList = createAsyncThunk(
  "customsClearance/fetchPurchaseList",
  async (filterOptions: PurchaseListParams) => {
    const response = await warehouseApi.fetchPurchaseList(filterOptions);
    return response;
  },
);

export const fetchPurchaseLines = createAsyncThunk(
  "customsClearance/fetchPurchaseLines",
  async (filterOptions: FetchPurchaseLines) => {
    const response = await warehouseApi.fetchPurchaseLines(filterOptions);
    return response;
  },
);

export const fetchCustomsClearancesPurchaseAdd = createAsyncThunk(
  "customsClearance/fetchCustomsClearancesPurchaseAdd",
  async (payload: FetchCustomsClearancesPurchaseAdd, thunkApi) => {
    const params = {
      customsClearancesId: payload.customsClearancesId,
      stoNumbers: payload.stoNumbers,
    };
    try {
      await customsClearanceApi.fetchCustomsClearancesPurchaseAdd(params);
      message.success("新增成功");
      if (payload.abnormalCost) {
        message.warning("進價疑似異常，請確認紅字進價");
      }
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message as string);
    }
  },
);

export const fetchCustomsClearancesPurchaseRemove = createAsyncThunk(
  "customsClearance/fetchCustomsClearancesPurchaseRemove",
  async (payload: FetchCustomsClearancesPurchaseRemove) => {
    await customsClearanceApi.fetchCustomsClearancesPurchaseRemove(payload);
    message.success("刪除成功");

    return "Success";
  },
);

export const fetchCustomsClearancesRecalculate = createAsyncThunk(
  "customsClearance/fetchCustomsClearancesRecalculate",
  async (customsClearancesId: number, thunkApi) => {
    try {
      await customsClearanceApi.fetchCustomsClearancesRecalculate(customsClearancesId);
      await thunkApi.dispatch(fetchCustomsClearance(customsClearancesId));
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message as string);
    }
  },
);

export const fetchCustomsClearancesApprove = createAsyncThunk(
  "customsClearance/fetchCustomsClearancesApprove",
  async (customsClearancesId: number, thunkApi) => {
    try {
      await customsClearanceApi.fetchCustomsClearancesApprove(customsClearancesId);
      await thunkApi.dispatch(fetchCustomsClearance(customsClearancesId));
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message as string);
    }
  },
);

export const fetchBatchUpdatePurchaseLine = createAsyncThunk(
  "customsClearance/fetchBatchUpdatePurchaseLine",
  async (payload: FetchBatchUpdatePurchaseLine[], thunkApi) => {
    try {
      await customsClearanceApi.fetchBatchUpdatePurchaseLine(payload);
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message as string);
    }
  },
);

export const fetchVendorList = createAsyncThunk("customsClearance/fetchVendorList", async (name: string, thunkApi) => {
  const params: FetchVendorListParam = {
    nameQ: name,
    limit: 20,
    offset: 0,
  };

  thunkApi.dispatch(updateVendorListParam(params));
  const response = await productApi.fetchVendorList(params);
  return response;
});

export const loadMoreVendorList = createAsyncThunk("customsClearance/loadMoreVendorList", async (_, thunkApi) => {
  const {
    product: { vendorListParam },
  } = thunkApi.getState() as RootState;

  const params: FetchVendorListParam = {
    ...vendorListParam,
    offset: vendorListParam.offset + vendorListParam.limit,
  };

  thunkApi.dispatch(updateVendorListParam(params));
  const response = await productApi.fetchVendorList(params);
  return response;
});

const customsClearanceSlice = createSlice({
  name: "customsClearance",
  initialState,
  reducers: {
    setCustomsClearanceFilter: (state, action) => {
      state.customsClearanceFilter = action.payload;
    },
    clearCustomsClearanceFilter: (state) => {
      state.customsClearanceFilter = initialState.customsClearanceFilter;
    },
    setPurchaseLinesFilter: (state, action) => {
      state.purchaseLinesFilter = action.payload;
    },
    clearPurchaseLinesFilter: (state) => {
      state.purchaseLinesFilter = initialState.purchaseLinesFilter;
    },
    clearError: (state) => {
      state.isEditDone = false;
      state.addErrorMessage = "";
      state.authorityErrorMessage = "";
    },
    updateVendorListParam: (state, action: PayloadAction<FetchVendorListParam>) => {
      state.vendorListParam = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomsClearanceList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCustomsClearanceList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.listRefresh = false;
      state.customsClearanceResult = action.payload;
    });
    builder.addCase(fetchCustomsClearanceList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchDeleteCustomsClearance.fulfilled, (state) => {
      state.listRefresh = true;
    });
    builder.addCase(fetchCreateCustomsClearances.fulfilled, (state) => {
      state.listRefresh = true;
    });
    builder.addCase(fetchCustomsClearance.fulfilled, (state, action) => {
      state.customsClearanceInfo = action.payload;
    });
    builder.addCase(fetchPurchaseList.pending, (state, action) => {
      state.isPurchaseLinesFetching = true;
    });
    builder.addCase(fetchPurchaseList.fulfilled, (state, action) => {
      state.purchaseListResult = action.payload;
      state.isPurchaseLinesFetching = false;
    });
    builder.addCase(fetchPurchaseLines.fulfilled, (state, action) => {
      state.purchaseLinesResult = action.payload;
    });
    builder.addCase(fetchCustomsClearancesPurchaseAdd.pending, (state) => {
      state.isEditDone = false;
    });
    builder.addCase(fetchCustomsClearancesPurchaseAdd.fulfilled, (state) => {
      state.isEditDone = true;
      state.addErrorMessage = "";
    });
    builder.addCase(fetchCustomsClearancesPurchaseAdd.rejected, (state, action) => {
      state.isEditDone = true;
      state.addErrorMessage = action.payload as string;
    });
    builder.addCase(fetchCustomsClearancesPurchaseRemove.pending, (state) => {
      state.isEditDone = false;
    });
    builder.addCase(fetchCustomsClearancesPurchaseRemove.fulfilled, (state) => {
      state.isEditDone = true;
      state.addErrorMessage = "";
    });
    builder.addCase(fetchCustomsClearancesRecalculate.pending, (state) => {
      state.isAuthorityDone = false;
    });
    builder.addCase(fetchCustomsClearancesRecalculate.fulfilled, (state) => {
      state.isAuthorityDone = true;
    });
    builder.addCase(fetchCustomsClearancesRecalculate.rejected, (state, action) => {
      state.authorityErrorMessage = action.payload as string;
    });
    builder.addCase(fetchCustomsClearancesApprove.pending, (state) => {
      state.isAuthorityDone = false;
    });
    builder.addCase(fetchCustomsClearancesApprove.fulfilled, (state) => {
      state.isAuthorityDone = true;
    });
    builder.addCase(fetchCustomsClearancesApprove.rejected, (state, action) => {
      state.authorityErrorMessage = action.payload as string;
    });
    builder.addCase(fetchBatchUpdatePurchaseLine.pending, (state) => {
      state.isEditDone = false;
      state.isPurchaseLinesFetching = true;
    });
    builder.addCase(fetchBatchUpdatePurchaseLine.fulfilled, (state) => {
      state.isEditDone = true;
      state.addErrorMessage = "";
      state.isPurchaseLinesFetching = false;
    });

    builder.addCase(fetchVendorList.fulfilled, (state, action) => {
      state.vendorListResult = action.payload;
    });
    builder.addCase(loadMoreVendorList.fulfilled, (state, action) => {
      state.vendorListResult = {
        ...action.payload,
        results: state.vendorListResult.results.concat(action.payload.results),
      };
    });
  },
});

export const customsClearanceState = (state: RootState) => state.customsClearanceSlice;
export const {
  setCustomsClearanceFilter,
  clearCustomsClearanceFilter,
  setPurchaseLinesFilter,
  clearPurchaseLinesFilter,
  clearError,
  updateVendorListParam,
} = customsClearanceSlice.actions;
export default customsClearanceSlice.reducer;
