/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { ExclamationCircleOutlined, FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { PurchaseList, Vendor } from "@api/utils/normalizeWarehouse";
import { RootState } from "@redux/rootReducer";
import {
  clearPurchaseListFilter,
  fetchContractVendors,
  fetchCopyPurchase,
  fetchDestroyPurchase,
  fetchPurchaseList,
  fetchStaffList,
  fetchSubmitSettlement,
  setPurchaseListFilter,
} from "@redux/warehouseSlice";
import { Button, Modal, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import type { SorterResult } from "antd/es/table/interface";
import { PurchaseListOrdering } from "@api/warehouseApi";
import { StaffPermission, usePermission } from "@hooks/usePermission";
import IntoWarehouseForm from "./IntoWarehouseEdit/IntoWarehouseForm";
import IntoWarehouseFilter from "./IntoWarehouseFilter";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;

const CustomLink = styled(Button)<{ noMargin?: boolean }>`
  margin-right: ${({ noMargin }) => (noMargin ? "0px" : "16px")};
  padding: 0;
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;

  // D202400301__[Urmart] 進倉後台介面優化 部分功能隱藏
  // const TariffNumber = styled(CustomText)
`;
//   cursor: pointer;
//   text-decoration: underline;
//   text-decoration-color: ${({ disabled, theme }) => !disabled && theme.colorSuccess500};
//   color: ${({ disabled, theme }) => (disabled ? theme.colorSuccess500 : theme.colorSuccess500)};
// `;

const CustomText = styled.p<{ disabled: boolean }>`
  margin-bottom: 0;
  color: ${({ disabled, theme }) => disabled && theme.colorNeutral400};
`;

const TariffNumber = styled(CustomText)`
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: ${({ disabled, theme }) => !disabled && theme.colorSuccess500};
  color: ${({ disabled, theme }) => (disabled ? theme.colorSuccess500 : theme.colorSuccess500)};
`;

const warehouseSlice = (state: RootState) => state.warehouseSlice;

export default function IntoWarehouse() {
  const dispatch = useDispatch();
  const { purchaseListResult, isFetching, purchaseListFilter } = useSelector(warehouseSlice);

  const [customerServiceToken] = usePermission([StaffPermission.CAN_MAKE_PURCHASE_SETTLEMENT]);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);

  const { offset, limit } = purchaseListFilter;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  const setPageLimit = (value: SelectValue) => {
    dispatch(setPurchaseListFilter({ ...purchaseListFilter, limit: parseInt(value as string, 10) }));
  };

  const onInvalid = (values: PurchaseList) => {
    Modal.confirm({
      title: "你確定要作廢這筆資料？",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchDestroyPurchase(values.id));
      },
    });
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
    dispatch(setPurchaseListFilter({ ...purchaseListFilter, offset: (value - 1) * limit }));
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: SorterResult<PurchaseList> | SorterResult<PurchaseList>[],
  ) => {
    const { order, columnKey } = sorter as SorterResult<PurchaseList>;
    // 不是點擊column排序時，不處理
    if (columnKey === undefined) return;
    // 沒有排序時，清除排序
    if (!order) {
      dispatch(
        setPurchaseListFilter({
          ...purchaseListFilter,
          ordering: undefined,
        }),
      );
      return;
    }
    // 有排序時，設定排序
    let ordering = "";
    switch (columnKey) {
      case "specDate":
        ordering = order === "ascend" ? PurchaseListOrdering.SPEC_DATE_ASC : PurchaseListOrdering.SPEC_DATE_DESC;
        break;
      case "stockinDate":
        ordering = order === "ascend" ? PurchaseListOrdering.STOCKIN_DATE_ASC : PurchaseListOrdering.STOCKIN_DATE_DESC;
        break;
      default:
    }
    dispatch(
      setPurchaseListFilter({
        ...purchaseListFilter,
        ordering,
      }),
    );
  };
  const handleSubmitSettlement = (purchaseId: number) => {
    dispatch(fetchSubmitSettlement({ purchaseId, warehouseCode: "TCAT" }));
  };

  const columns: ColumnsType<PurchaseList> = [
    {
      key: "stoNumber",
      dataIndex: "stoNumber",
      title: "LMS編號",
      render: (value: string, others: PurchaseList) => (
        <CustomText disabled={!!others.destroyedAt}>{value || "N/A"}</CustomText>
      ),
    },
    {
      key: "vendorName",
      dataIndex: "vendor",
      title: "廠商名稱",
      render: (value: Vendor, others: PurchaseList) => {
        return <CustomText disabled={!!others.destroyedAt}>{value?.name || "N/A"}</CustomText>;
      },
    },
    {
      key: "ownerName",
      dataIndex: "vendor",
      title: "商開負責人",
      render: (value: Vendor, others: PurchaseList) => (
        <CustomText disabled={!!others.destroyedAt}>{value?.owner?.name || "N/A"}</CustomText>
      ),
    },
    {
      key: "totalRequestQty",
      dataIndex: "totalRequestQty",
      title: "申請進貨總量(件)",
      render: (value: string, others: PurchaseList) => (
        <CustomText disabled={!!others.destroyedAt}>{value || "N/A"}</CustomText>
      ),
    },
    {
      key: "totalArrivedQty",
      dataIndex: "totalArrivedQty",
      title: "實際進貨總量(件)",
      render: (value: string, others: PurchaseList) => (
        <CustomText disabled={!!others.destroyedAt}>{value || "N/A"}</CustomText>
      ),
    },
    {
      key: "specDate",
      dataIndex: "specDate",
      title: "預期進倉日期",
      sorter: true,
      render: (value: string, others: PurchaseList) => (
        <CustomText disabled={!!others.destroyedAt}>{value || "N/A"}</CustomText>
      ),
    },
    {
      key: "stockinDate",
      dataIndex: "stockinDate",
      sorter: true,
      title: "倉庫進倉日期",
      render: (value: string, others: PurchaseList) => (
        <CustomText disabled={!!others.destroyedAt}>{value || "N/A"}</CustomText>
      ),
    },
    {
      key: "actStockinDate",
      dataIndex: "actStockinDate",
      sorter: true,
      title: "實際入庫時間",
      render: (value: string, others: PurchaseList) => (
        <CustomText disabled={!!others.destroyedAt}>{value || "N/A"}</CustomText>
      ),
    },
    {
      key: "applicationDate",
      dataIndex: "applicationDate",
      title: "上傳 LMS 時間",
    },
    // D202400301__[Urmart] 進倉後台介面優化 部分功能隱藏
    // {
    //   key: "tariffNumber",
    //   dataIndex: "tariffNumber",
    //   title: "進口報關單號",
    //   render: (value: string, others: PurchaseList) =>
    //     value ? (
    //       <TariffNumber
    //         disabled={!!others.destroyedAt}
    //         onClick={() => window.open(`${window.location.origin}/customs-clearance/${others.customsClearance}`)}
    //       >
    //         {value}
    //       </TariffNumber>
    //     ) : (
    //       <CustomText disabled={!!others.destroyedAt}>N/A</CustomText>
    //     ),
    // },
    {
      key: "id",
      dataIndex: "id",
      title: "",
      fixed: "right",
      render: (value: number, others: PurchaseList) => {
        return (
          <Row justify="end" align="middle" wrap={false}>
            {customerServiceToken && (
              <CustomLink
                type="link"
                onClick={() => handleSubmitSettlement(value)}
                disabled={!others.stockinDate || !!others.actStockinDate}
              >
                上架完成
              </CustomLink>
            )}
            <CustomLink type="link" onClick={() => window.open(`${window.location.href}/${others.id}`)}>
              {others.destroyedAt || others.stoNumber ? "檢視" : "編輯"}
            </CustomLink>
            {/* D202400301__[Urmart] 進倉後台介面優化 部分功能隱藏 */}
            {/* <CustomLink type="link" onClick={() => dispatch(fetchCopyPurchase(others.id))}>
              複製
            </CustomLink> */}
            <CustomLink type="link" noMargin disabled={!!others.destroyedAt} onClick={() => onInvalid(others)}>
              作廢
            </CustomLink>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchPurchaseList(purchaseListFilter));
  }, [dispatch, purchaseListFilter]);

  useEffect(() => {
    dispatch(fetchContractVendors());
    return () => {
      // 清除slice裡的filter，避免下次進入進倉頁面時，filter還是保留上次的狀態
      dispatch(clearPurchaseListFilter());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStaffList({ staffGroupPermission: "product_owner", limit: 100, offset: 0 }));
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="進倉" />
      <Row justify="space-between">
        <div>
          {/* D202400301__[Urmart] 進倉後台介面優化 部分功能隱藏 */}
          {/* <Button
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => setOpenForm(true)}
            style={{ marginRight: "10px" }}
          >
            新增進倉單
          </Button> */}
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
        </div>
        <Row align="middle">
          <PageText>{`總共${purchaseListResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <IntoWarehouseFilter isVisible={showFilter} />
      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          loading={isFetching}
          columns={columns}
          dataSource={purchaseListResult.results}
          pagination={{
            pageSize: purchaseListFilter.limit,
            current: currentPage,
            showSizeChanger: false,
            total: purchaseListResult.count,
            onChange: fetchPageChange,
          }}
          onChange={handleTableChange}
        />
      </TableWrapper>
      {openForm && <IntoWarehouseForm close={() => setOpenForm(false)} />}
    </Wrapper>
  );
}
